import api from './api';

interface formProps {
  cupomName: string;
  companyId: string | null;
  userId: string
}

const verifyCoupon = async ({ cupomName, companyId, userId }: formProps) => {
  const search = await api
    .get(`cupons/verify/${cupomName}`,{params:{companyId,userId}})
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log(err);
      return false;
    });

  return search;
};

export default verifyCoupon;