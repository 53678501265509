import React from 'react';
import styled from 'styled-components';
import Colors from '../utils/Colors';

interface ProgressBarProps {
  amount: number;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ amount }) => {
  
  return(
    <Bar amount={amount} />
  )
}

interface BarProps {
  amount: number;
}

const Bar = styled.div<BarProps>`
  height: 1px;
  width: ${props => props.amount }%;
  flex-direction: row;
  align-items: center;
  padding-horizontal: 20px;
  border-top-width: 5px;
  border-top-color: ${Colors.orange};
  border-top-right-radius:6px;
  border-bottom-right-radius:6px;
`;

export default ProgressBar;