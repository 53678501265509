import React from 'react';
import styled from 'styled-components';
import Colors from '../utils/Colors';

interface ButtonProps {
  type: string;
  text: string;
  icon?: string | null;
  action: () => void;
}
interface ButtonTypes {
  active: string;
  inactive: string;
  selected: string;
  [key: string]: string;
}
const backgrounds: ButtonTypes = {
  selected: 'orange',
  active: 'lightOrange',
  inactive: 'transparent',
}
const colors: ButtonTypes = {
  selected: 'white',
  active: 'orange',
  inactive: 'darkGray',
}
function getBackground(type:string) {
    return backgrounds[type] || ''
}
  function getColor(type:string) {
    return colors[type] || ''
  }
const SelectBox: React.FC<ButtonProps> = ({ type, text, action }) => {
  return (
    <Container onClick={action} background={getBackground(type)} border={type}>
      <Label color={getColor(type)}>{text}</Label>
    </Container>
  )
}

interface ContainerProps {
  background: string;
  border: string;
}
interface TextProps {
  color: string;
}

const Container = styled.button<ContainerProps>`
  height: 48px;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  background: ${props => Colors[props.background] };
  margin-vertical: 8px;
  border: ${props => props.border === 'inactive' ? '1px solid #A3A3A3' : 'none'};
  padding-horizontal: 20px;
  flex-direction: row;
  width: 100%;
  cursor: pointer;
`;

const Label = styled.div<TextProps>`
  color: ${props => Colors[props.color] };
  font-family: 'Roboto';
  font-size: 16px;
  letter-spacing: 0.2px;
  text-align: center;
`;

export default SelectBox;