import styled from 'styled-components';
import Lottie from 'react-lottie';
import animationData from '../images/loading.json';

export default function Loading() {
  const defaultOptions = {
    loop: true, // Defina como `false` se você não deseja que a animação seja repetida
    autoplay: true, // Inicie a animação assim que o componente for montado
    animationData: animationData, // Importe o arquivo de animação JSON
  };

  return (
    <Container>
      <Lottie options={defaultOptions} height={220} width={220} />
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(250, 250, 220, 1);
  justify-content: center;
  align-items: center;
  z-index: 2;
  position: absolute;
  display:flex
`;
