import React from 'react';
import styled from 'styled-components';
import Colors from '../utils/Colors';

interface TitleProps {
  text: string;
}

const Title: React.FC<TitleProps> = ({ text }) => {
  
  return(
    <Container>
      <Text>{text}</Text>
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  padding: 24px 24px 0;
  margin-bottom: 10px;
`;

const Text = styled.div`
  color: ${Colors.black };
  text-align: center;
  font-family: 'Roboto';
`;

export default Title;