import React from 'react';
import styled from 'styled-components';
import LogoIcon from "../images/planet.png";

const LogoPlanet: React.FC = () => {
  
  return(
    <Logo src={LogoIcon} />
  )
}

const Logo = styled.img`
  width: 90px;
  align-self: center;
`;

export default LogoPlanet;