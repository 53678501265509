import React from "react";
import { Routes, Route } from "react-router-dom";
import PaymentRegister from "../pages/PaymentRegister";
import Finish from "../pages/Finish";

const AppRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<PaymentRegister />} />
      <Route path="/finish" element={<Finish />} />
    </Routes>
  );
}

export default AppRoutes;