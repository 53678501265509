import React from 'react';
import styled from 'styled-components';
import Colors from '../utils/Colors';

interface SubtitleProps {
  text: string;
}

const Subtitle: React.FC<SubtitleProps> = ({ text }) => {
  
  return(
    <Container>
      <Text>{text}</Text>
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  padding: 0 24px;
  margin-bottom: 30px;
`;

const Text = styled.div`
  color: ${Colors.lightBlack };
  text-align: center;
  font-size: 14px;
`;


export default Subtitle;