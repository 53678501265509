import React from "react";
import styled, { css } from 'styled-components';
import "./styles.css";
import { Link, useNavigate } from "react-router-dom";
import Colors from '../../utils/Colors';
import LogoPlanet from "../../components/LogoPlanet";
import Button from "../../components/Button";
import Title from "../../components/Title";
import Subtitle from "../../components/Subtitle";

const Finish: React.FC = () => {

  const navigate = useNavigate();


  return (
    <MainContainer>
      <Card>
        <LogoPlanet />
        <Title text="Plano ativado com sucesso" />
        <Subtitle text="Agora você faz parte do nosso mundo Kiddle Pass! Aproveite todo nosso conteúdo." />
        <ButtonContainer>
          <a href="kiddleapp://"
            target="_blank"
            rel="noopener noreferrer"
            style={{
            display: 'flex',
            flexDirection: 'row',
            height: '56px',
            width: '100%',
            padding: '16px',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '50px',
            background: Colors.orange,
            margin: '8px',
            cursor: 'pointer',
            color: Colors.white,
            fontFamily: 'Roboto',
            fontSize: 18,
            fontWeight: 'bold',
            textAlign: 'center',
            textDecoration: 'none',
          }}>Abrir App</a>
        </ButtonContainer>
      </Card>
    </MainContainer >
  );
};

const MainContainer = styled.div`
  background: ${Colors.lightYellow};
  height: 100vh;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
`;

const Card = styled.div`
  background: ${Colors.white};
  border-radius: 16px;
  align-self: center;
  padding: 48px 16px;
  display: flex;
  flex-direction: column;
  width: 50%;
  box-shadow: 0px 3px 9px rgba(100, 100, 107, 0.4);
  @media (max-width: 768px) {
    width: 90%;
  }
`;

const ButtonContainer = styled.div`
  align-self: center;
  min-width: 260px;
  margin-top: 24px;
`;

export default Finish;