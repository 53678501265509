import api from './api';

interface FormProps {
  user: User;
  creditCard: CreditCard | null,
  payment: Payment
}

interface User {
  id: string;
  cpf: string | null;
  address: Address | null;
  id_minutrade: string | null;
  company_id: string | null
}

interface Address {
  street: string,
  zipcode: string,
  neighborhood: string,
  city: string,
  state: string
}

interface CreditCard {
  holder_name: string;
  card_expiration: string;
  card_number: string;
  card_cvv: string;
  payment_company_code: string;
}

interface Payment {
  isPix: boolean | null;
  isApple: boolean;
  plan_id: string | null;
  product_items: ProductItems[] | null;
  cupomId: string | null;
  cupomPlanKind: string | null;
  collaboratorId: string | null;
}

interface ProductItems {
  product_id: string;
  discounts: Discounts[];
}

interface Discounts {
  discount_type: string;
  amount: number;
}

const userRegister = async ({ user, creditCard, payment }: FormProps) => {
  const result = await api
    .patch(`users/register-plan/${user.id}`, {
      isPix: payment.isPix,
      companyId: user.company_id,
      plan_id: payment.plan_id,
      registry_code: user.cpf,
      address: user.address,
      holder_name: creditCard?.holder_name,
      card_expiration: creditCard?.card_expiration,
      card_number: creditCard?.card_number,
      card_cvv: creditCard?.card_cvv,
      payment_company_code: creditCard?.payment_company_code,
      product_items: payment.product_items,
      cupomId: payment.cupomId,
      cupomPlanKind: payment.cupomPlanKind,
      collaboratorId: payment.collaboratorId,
      id_minutrade: user.id_minutrade
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log(err);
      return false;
    });

  return result;
};

export default userRegister;