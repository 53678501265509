import styled from 'styled-components';
import Lottie from 'react-lottie';
import Colors from '../utils/Colors';
import animationData from '../images/success.json';

export default function Loading() {
  const defaultOptions = {
    loop: false, // Defina como `false` se você não deseja que a animação seja repetida
    autoplay: true, // Inicie a animação assim que o componente for montado
    animationData: animationData, // Importe o arquivo de animação JSON
  };

  return (
    <Container>
      <Lottie options={defaultOptions} height={300} width={300} />
      <TextBig>Hey Kiddle! Seu plano já está ativo.</TextBig>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(250, 250, 220, 1);
  justify-content: center;
  align-items: center;
  z-index: 2;
  position: absolute;
  display: flex;
  flex-direction: column;
`;

const TextBig = styled.span`
  color: ${Colors.lightBlack };
  font-family: 'Roboto';
  font-size: 20px;
  text-align: center;
  font-weight: bold;
  padding: 20px 64px
`;