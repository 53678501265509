import api from './api';

interface formProps {
  collaboratorId: string;
}

const checkCollaborator = async ({ collaboratorId }: formProps) => {
  const search = await api
    .get(`/collaborators/many/${collaboratorId}`, {})
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log(err);
      return false;
    });

  return search;
};

export default checkCollaborator;