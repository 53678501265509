import {useState,useEffect} from 'react';
import styled from 'styled-components';
import { useLocation, useNavigate } from "react-router-dom";
import { IMaskInput } from 'react-imask';

import Colors from '../utils/Colors';

import ProgressBar from '../components/ProgressBar';
import Title from '../components/Title';
import Subtitle from '../components/Subtitle';
import Button from '../components/Button';
import Input from '../components/Input';
import SelectBox from '../components/SelectBox';
import LogoPlanet from '../components/LogoPlanet';
import Loading from '../components/Loading';
import NoUser from '../components/NoUser';
import UserActivated from '../components/UserActivated';

import { creditCardType } from '../utils/creditCardType';
import validateCEP from '../utils/validateCEP';

import verifyUser from '../services/verifyUser';
import verifyCoupon from '../services/verifyCoupon';
import verifyCollaborator from '../services/verifyCollaborator';
import checkCollaborator from '../services/checkCollaborator';
import subscriptionRegister from '../services/subscriptionRegister';

export default function PaymentRegister() {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const u = searchParams.get('u');
  const clb = searchParams.get('clb');
  const cpm = searchParams.get('cpm');
  
  const paymentMethod = location.state?.paymentMethod ?? null;

  const [id_minutrade, setIdMinutrade] = useState(null)
  const [company_id, setCompanyId] = useState(null)
  const [collaboratorId, setCollaboratorId] = useState(null)
  const [plan_id, setPlanId] = useState(null)
  const [planKind, setPlanKind] = useState(null)
  const [planValue, setPlanValue] = useState(null)
  const [coupon, setCoupon] = useState(null)
  const [code, setCode] = useState('')

  const [cardCVV, setCardCVV] = useState('')
  const [method, setMethod] = useState(paymentMethod??'credit_card')
  const [cardNumber, setCardNumber] = useState('')
  const [cardName, setCardName] = useState('')
  const [cardDate, setCardDate] = useState('')
  const [cpf, setCPF] = useState('')
  const [cep, setCEP] = useState('')
  const [street, setStreet] = useState(''); 
  const [city, setCity] = useState('');
  const [uf, setUF] = useState('');
  const [neighborhood, setNeighborhood] = useState('');
  const [validTxt, setValidTxt] = useState('')
  const [errorTxt, setErrorTxt] = useState('')
  const [loading, setLoading] = useState(false)
  const [buttonType, setButtonType] = useState('disabled')
  const [price, setPrice] = useState('49.90')
  const [trialDays, setTrialDays] = useState('7')

  const [userError, setUserError] = useState(false)
  const [activated, setActivated] = useState(false)
  const [freeAccess, setFreeAccess] = useState(false)

  useEffect(() => { 
    setLoading(true)
    const getUser = async () => {
      if (!u) {
        setLoading(false)
        setUserError(true)
        return
      }
      const result = await verifyUser({ userId:u, collaboratorId:clb, cupomId:cpm })
      if (result) {
        if (result.message === 'User not found') {
          setUserError(true)
        }
        if (result.assinatura_status === 'Ativa') {
          setActivated(true)
        }
        if (cpm) {
          let newCoupon
          newCoupon = result
          newCoupon.id = cpm
          setCoupon(newCoupon)
          setPlanKind(newCoupon.planKind)
        }
        setIdMinutrade(result.id_minutrade)
        setCompanyId(result.company_id)
        setPlanId(result.plan_id)
        setPlanValue(result.planValue)
        if (result.planValue) {
          setPrice((result.planValue.toFixed(2)))
        }
        setTrialDays(result.planTrialDays ?? '7')
      }
      else {
        setUserError(true)
      }
      setTimeout(()=> setLoading(false), 2000);
    }
    getUser()
  }, [])
  
  useEffect(() => {
    if (cep.length === 9) {
      getCEP();
    }
  }, [cep]);

  useEffect(() => {
    updateButton();
  }, [method, cardNumber, cardName, cardDate, cardCVV, cpf, cep]);

  const getCEP = async () => {
    const validationCEP = await validateCEP(cep);
    if (validationCEP.erro) {
      setLoading(false);
      return;
    }
    setStreet(validationCEP.logradouro);
    setCity(validationCEP.localidade);
    setNeighborhood(validationCEP.bairro);
    setUF(validationCEP.uf);
  };

  const updateButton = () => {
    if (cpf.length === 14 && cep.length === 9) {
      if (method === 'pix' && !loading) {
        setButtonType('primary')
      } else {
        if (cardNumber.length === 19 && cardName && cardDate && cardCVV && !loading) {
          setButtonType('primary')
        } else {
          setButtonType('disabled')
        }
      }
    } else {
      setButtonType('disabled')
    }
  }

  const send = async () => {
    setErrorTxt("")
    setLoading(true)
    const address = {
      street,
      zipcode: cep,
      city,
      neighborhood,
      state:uf
    }
    const sendUser = { id: u, cpf, address, id_minutrade, company_id }
    const payment = {
      isPix: method === 'pix',
      plan_id,
      cupomId: coupon?.id ?? null,
      collaboratorId: clb || collaboratorId || null,
      cupomPlanKind: planKind,
      product_items: planValue ? [{
        product_id: 1099948,
        discounts: [
          {
            discount_type: 'amount',
            amount: 49.90 - (planValue.toFixed(2))
          }
        ]
      }] : null
    }
    let creditCard = null
    if (method === "credit_card") {
      const inputCard = cardNumber.replace(/\s+/g, '');
      const brand = creditCardType(inputCard);
      creditCard = {
        holder_name: cardName,
        card_expiration: cardDate,
        card_number: cardNumber,
        card_cvv: cardCVV,
        payment_company_code: brand,
      }
    }
    const returnAPI = await subscriptionRegister({ user: sendUser, payment, creditCard:freeAccess?null:creditCard })
    setLoading(false)
    if (returnAPI.success) {
      return navigate('/finish')
    } else {
      return setErrorTxt("Erro ao finalizar, por favor tente novamente.")
    }
  }

  const checkCode = async () => {
    setErrorTxt('')
    setValidTxt('')
    setLoading(true)
    const isValid = await verifyCoupon({ cupomName: code, companyId: company_id, userId:u })
    if (isValid.success) {
      const messages = {
        free: "Tudo certo! Você ganhou acesso grátis.",
        discount: `Tudo certo! O valor do seu plano será ${isValid.planValue?.toFixed(2)}, ative o plano para aplicar o cupom.`,
        discount_no_trial: `Tudo certo! O valor do seu plano será ${isValid.planValue?.toFixed(2)}, ative o plano para aplicar o cupom.`,
        trial: `Tudo certo! Você ganhou ${isValid.planTrialDays} dias grátis, ative o plano para aplicar o cupom.`,
        trial_no_subscription: `Tudo certo! Você ganhou ${isValid.planTrialDays} dias grátis, ative o plano para aplicar o cupom.`,
        saleExpiration: `Tudo certo! O valor do seu plano será ${isValid.planValue?.toFixed(2)}, ative o plano para aplicar o cupom.`,
        trial_discount: `Tudo certo! O seu plano será ${isValid.planValue?.toFixed(2)} e você ganhou ${isValid.planTrialDays} dias grátis, ative o plano para aplicar o cupom.`,
      };
      setCoupon(isValid)
      setPrice(isValid.planValue ? isValid.planValue?.toFixed(2) : '49.90')
      setTrialDays(isValid.planTrialDays ?? '7')
      setPlanId(isValid.plan_id)
      setPlanKind(isValid.planKind)
      if (isValid.planValue) {setPlanValue(isValid.planValue) }
      
      if (isValid.planKind === 'free' || isValid.planKind === 'trial_no_subscription') {
        setFreeAccess(true)
        setButtonType('primary')
      }
      setTimeout(()=> setLoading(false), 2000);
      return setValidTxt(messages[isValid.planKind])
    }

    // NOVO FLUXO VALIDACAO BENEFÍCIO
    const collaboratorFind = await checkCollaborator({ collaboratorId: code.toLowerCase() })
    if (collaboratorFind.success) {
      const collaborator = collaboratorFind.collaborators[0]
      const messages = {
        free: "Tudo certo! Você ganhou acesso grátis.",
        discount: `Tudo certo! O valor do seu plano será ${collaborator.planValue?.toFixed(2)}, ative o plano para aplicar o cupom.`,
        discount_no_trial: `Tudo certo! O valor do seu plano será ${collaborator.planValue?.toFixed(2)}, ative o plano para aplicar o cupom.`,
        trial: `Tudo certo! Você ganhou ${collaborator.planTrialDays} dias grátis, ative o plano para aplicar o cupom.`,
        trial_no_subscription: `Tudo certo! Você ganhou ${collaborator.planTrialDays} dias grátis, ative o plano para aplicar o cupom.`,
        saleExpiration: `Tudo certo! O valor do seu plano será ${collaborator.planValue?.toFixed(2)}, ative o plano para aplicar o cupom.`,
        trial_discount: `Tudo certo! O seu plano será ${isValid.planValue?.toFixed(2)} e você ganhou ${isValid.planTrialDays} dias grátis, ative o plano para aplicar o cupom.`,
      };
      setPrice(collaborator.planValue ? collaborator.planValue?.toFixed(2) : '49.90')
      setTrialDays(collaborator.planTrialDays ?? '7')
      setPlanId(collaborator.plan_id)
      setPlanKind(collaborator.planKind)
      setCompanyId(collaborator.companyId)
      setCollaboratorId(collaborator.id)
      if (collaborator.planValue) { setPlanValue(collaborator.planValue) }
      if (collaborator.planKind === 'free' || collaborator.planKind === 'trial_no_subscription') {
        setFreeAccess(true)
        setButtonType('primary')
      }
      setTimeout(()=> setLoading(false), 2000);
      return setValidTxt(messages[isValid.planKind])
    }
    setTimeout(()=> setLoading(false), 2000);
    return setErrorTxt('Cupom inválido. Verifique se digitou corretamente e tente novamente.')
  }
  
  return (
    <>
      {
        loading && (
          <Loading />
        )
      }
      {
        !loading && userError && (
          <NoUser />
        )
      }
      {
        !loading && activated && (
          <UserActivated />
        )
      }
      {!loading && !userError && !activated &&(
        <MainContainer>
          <Card>
            <ProgressBar amount={66} />
            <LogoPlanet />
            <PriceContainer>
              {
                price === '49.90' && (
                  <Row>
                    <TextBoldPlan>R$ 49,90/mês</TextBoldPlan>
                  </Row>
                )
              }
              {
                price !== '49.90' && (
                  <>
                    <Row>
                      <TextOldPrice>R$ 49,90/mês</TextOldPrice>
                    </Row>
                    <Row>
                      <TextBig>R$ {price}/mês</TextBig>
                    </Row>
                  </>
                )
              }
              {
                trialDays > 0 && (
                  <TrialContainer>
                    <TextBoldPlan>
                      {trialDays} dias grátis
                    </TextBoldPlan>
                  </TrialContainer>
                )
              }
            </PriceContainer>
            {
              !clb && !cpm && (
                <>
                  <Title text='Cupom promocional' />
                  <Subtitle text='Se você possui um cupom insira no campo abaixo' />
                  <Input
                    text='Digite o cupom aqui'
                    onChange={text => {
                      setErrorTxt('')
                      setValidTxt('')
                      setCode(text.toUpperCase());
                    }}
                    value={code}
                    isPassword={false}
                  />
                </>
              )
            }
            
            {
              errorTxt?.length > 0 && (
                <TextOrange>{errorTxt}</TextOrange>
              )
            }
            {
              validTxt?.length > 0 && (
                <TextGreen>{validTxt}</TextGreen>
              )
            }
            {
              !clb && !cpm && (
                <>
                  <ButtonContainer>
                    <Button
                      text={'Validar cupom'}
                      action={checkCode}
                      type={code ? 'primary':'disabled'}
                    />
                  </ButtonContainer>
                </>
              )
            }
            
            {
              !freeAccess && (
                <>
                <Title text='Método de pagamento' />
              <Subtitle text='Escolha um dos métodos de pagamento abaixo' />
              <Row>
                <SelectContainer>
                  <SelectBox
                    text="Cartão"
                    action={() => setMethod('credit_card')} 
                    type={method === 'credit_card' ? 'selected' : 'inactive'}
                  />
                </SelectContainer>
                <SelectContainer>
                  <SelectBox
                    text="Pix"
                    action={() => setMethod('pix')} 
                    type={method === 'pix' ? 'selected' : 'inactive'}
                  />
                </SelectContainer>
              </Row>
              {
                method === 'credit_card' && (
                  <>
                    <Label>Número do cartão</Label>
                    <MaskContainer>
                      <IMaskInput
                        mask="0000 0000 0000 0000"
                        value={cardNumber}
                        className="input"
                        onAccept={(value) => { setCardNumber(value) }}
                        placeholder="0000 0000 0000 0000"
                        style={{
                          width: '100%',
                          alignSelf: 'center',
                          border: '1px solid',
                          borderColor:`${Colors.darkGray}`,
                          borderRadius: 30,
                          padding: `15px 30px`,
                          marginBottom: 16,
                        }}
                      />
                    </MaskContainer>
                    <Label>Nome do titular</Label>
                    <Input
                      text='Digite seu nome aqui'
                      onChange={text => {
                        setCardName(text);
                      }}
                      value={cardName}
                      isPassword={false} />
                    <Row>
                      <Col>
                        <Label>Validade</Label>
                        <IMaskInput
                          mask="00/00"
                          value={cardDate}
                          className="input"
                          onAccept={(value) => { setCardDate(value) }}
                          placeholder="MM/AA"
                          style={{
                            width: '100%',
                            alignSelf: 'center',
                            border: '1px solid',
                            borderColor:`${Colors.darkGray}`,
                            borderRadius: 30,
                            padding: `15px 30px`,
                            marginBottom: 16,
                            display: 'flex',
                          }}
                        />
                      </Col>
                      <Col>
                        <Label>CVV</Label>
                        <Input
                          text='000'
                          onChange={text => {
                            setCardCVV(text);
                          }}
                          value={cardCVV}
                          isPassword={true} />
                      </Col>
                    </Row>
                  </>
                )
              }
              {
                method === 'pix' && (
                  <>
                    <TextBold>Deseja usar Pix?</TextBold>
                    <Text>Você receberá a fatura pelo email cadastrado.</Text>
                  </>
                )
              }
              {
                <>
                  <Label>CPF</Label>
                  <MaskContainer>
                    <IMaskInput
                      mask="000.000.000-00"
                      value={cpf}
                      className="input"
                      onAccept={(value) => { setCPF(value) }}
                      style={{
                        width: '100%',
                        alignSelf: 'center',
                        border: '1px solid',
                        borderColor:`${Colors.darkGray}`,
                        borderRadius: 30,
                        padding: `15px 30px`,
                        marginBottom: 16,
                        display: 'flex',
                      }}
                    />
                  </MaskContainer>
                  <Label>CEP</Label>
                  <MaskContainer>
                    <IMaskInput
                      mask="00000-000"
                      value={cep}
                      className="input"
                      onAccept={(value) => { setCEP(value) }}
                      style={{
                        width: '100%',
                        alignSelf: 'center',
                        border: '1px solid',
                        borderColor:`${Colors.darkGray}`,
                        borderRadius: 30,
                        padding: `15px 30px`,
                        marginBottom: 16,
                        display: 'flex',
                      }}
                    />
                  </MaskContainer>
                </>
              }
              {
                errorTxt.length > 0 && (
                  <TextOrange>{errorTxt}</TextOrange>
                )
              }
              </>
              )
              
            }
            <ButtonContainer>
              <Button
                text={'Ativar plano'}
                action={send}
                type={buttonType}
              />
            </ButtonContainer>
          </Card>
        </MainContainer>
      )
      }
      
    </>
  );
}

const MainContainer = styled.div`
  background: ${Colors.lightYellow};
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  padding-bottom: 48px;
`;

const Card = styled.div`
  background: ${Colors.white};
  border-radius: 16px;
  align-self: center;
  padding: 32px 16px;
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-top: 48px;
  box-shadow: 0px 3px 9px rgba(100, 100, 107, 0.4);
  @media (max-width: 768px) {
    width: 98%;
    margin-top: 16px;
  }
`;
const MaskContainer = styled.div`
  width: 60%;
  display:flex;
  align-self:center;
  @media (max-width: 768px) {
    width: 90%;
  }
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  @media (max-width: 768px) {
    padding-left: 16px;
    padding-right: 8px;
  }
`;
const Col = styled.div`
  display: flex;
  flex-direction: column;

`;
const SelectContainer = styled.div`
  width: 100px;
  margin-right: 8px;
  margin-bottom: 24px;
`;
const Label = styled.span`
  color: ${Colors.black };
  font-family: 'Roboto';
  padding-bottom: 5px;
  text-align: center;
`;

const TextBold = styled.span`
  color: ${Colors.black };
  font-family: 'Roboto';
  font-size: 20px;
  text-align: center;
  margin-top: 8px;
  padding-horizontal: 24px;
`;
const Text = styled.span`
  color: ${Colors.lightBlack };
  font-family: 'Roboto';
  font-size: 14px;
  text-align: center;
  margin: 16px 0 40px;
  padding-horizontal: 30px;
`;

const TextOrange = styled.span`
  color: ${Colors.orange };
  font-family: 'Roboto';
  font-size: 14px;
  text-align: center;
  margin: 8px 24px;
  font-weight: bold;
`;

const TextGreen = styled.span`
  color: ${Colors.green };
  font-family: 'Roboto';
  font-size: 14px;
  text-align: center;
  margin: 8px 24px;
  font-weight: bold;
`;

const ButtonContainer = styled.div`
  align-self: center;
  min-width: 160px;
`;

const PriceContainer = styled.div`
  align-self: center;
  text-align: center;
  margin-top: 20px;
`;
const TrialContainer = styled.div`
  align-self: center;
`;
const TextBoldPlan = styled.span`
  color: ${Colors.orange };
  font-family: 'Roboto';
  font-size: 18px;
  text-align: center;
  font-weight: bold;
`;
const TextOldPrice = styled.span`
  color: ${Colors.orange };
  font-family: 'Roboto';
  font-size: 14px;
  text-align: center;
  line-height: 20px;
  text-decoration: line-through;
`;
const TextBig = styled.span`
  color: ${Colors.orange };
  font-family: 'Roboto';
  font-size: 24px;
  text-align: center;
  padding-bottom: 8px;
  font-weight: bold;
`;