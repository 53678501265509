import React from 'react';
import styled from 'styled-components';
import Colors from '../utils/Colors';

interface InputProps {
  text: string;
  value: string;
  isPassword: boolean;
  onChange: (txt:string) => void
}

const Input: React.FC<InputProps> = ({ text, onChange, value, isPassword }) => {
  
  return(
    <Container
      placeholder={text}
      onChange={(event)=>onChange(event.target.value)}
      value={value}
      type={isPassword ?"password":'text'}
      autoCapitalize='none'
    >
    </Container>
  )
}

const Container = styled.input`
  width: 60%;
  align-self: center;
  border: 1px solid ${Colors.darkGray };
  border-radius: 30px;
  padding: 15px 30px;
  margin-bottom: 16px;
  display: flex;
  @media (max-width: 768px) {
    width: 90%;
  }
`;

export default Input;