import api from './api';

interface formProps {
  userId: string;
  collaboratorId: string | null;
  cupomId: string | null;
}

const verifyUser = async ({ userId, collaboratorId,cupomId }: formProps) => {
  const search = await api
    .get(`/users/details/${userId}`,{params:{collaboratorId,cupomId}})
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log(err);
      return false;
    });

  return search;
};

export default verifyUser;