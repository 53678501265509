export function creditCardType(cc:string) {
  const amex = new RegExp('^3[47][0-9]{13}$');
  const visa = new RegExp('^4[0-9]{12}(?:[0-9]{3})?$');

  const mastercard = new RegExp('^5[1-5][0-9]{14}$');
  const mastercard2 = new RegExp('^2[2-7][0-9]{14}$');

  const disco1 = new RegExp('^6011[0-9]{12}[0-9]*$');
  const disco2 = new RegExp('^62[24568][0-9]{13}[0-9]*$');
  const disco3 = new RegExp('^6[45][0-9]{14}[0-9]*$');

  const diners = new RegExp('^3[0689][0-9]{12}[0-9]*$');

  if (visa.test(cc)) {
    return 'visa';
  }
  if (amex.test(cc)) {
    return 'amex';
  }
  if (mastercard.test(cc) || mastercard2.test(cc)) {
    return 'mastercard';
  }
  if (disco1.test(cc) || disco2.test(cc) || disco3.test(cc)) {
    return 'discover';
  }
  if (diners.test(cc)) {
    return 'dinners';
  }
  return undefined;
}