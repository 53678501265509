import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material';
import AppRoutes from "./routes";

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

declare module '@mui/material' {
  interface Theme {
    status: {
      danger: string;
    };
  }
  interface ThemeOptions {
    status?: {
      danger?: string;
    };
  }
}

const theme = createTheme({
  typography: {
    h1: {
      fontFamily: ['Roboto'].join(','),
      fontWeight: 700,
      fontSize: 26,
    },
    h2: {
      fontFamily: ['Roboto'].join(','),
      fontWeight: 400,
      fontSize: 14,
    },
    h3: {
      fontFamily: ['Roboto'].join(','),
      fontWeight: 400,
      fontSize: 12,
    },
    h4: {
      fontFamily: ['Roboto'].join(','),
      fontWeight: 500,
      fontSize: 20,
    },
    h5: {
      fontFamily: ['Roboto'].join(','),
      fontWeight: 600,
      fontSize: 14,
    },
    h6: {
      fontFamily: ['Roboto'].join(','),
      fontWeight: 500,
      fontSize: 10,
    },
  },
});

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppRoutes />
    </ThemeProvider>
  );
};

export default App;
