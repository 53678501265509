import React from 'react';
import styled from 'styled-components';
import Colors from '../utils/Colors';

interface ButtonProps {
  type: string;
  text: string;
  icon?: string;
  action: () => void | null;
}

interface ButtonTypes {
  primary: string;
  primaryWhite: string;
  secondary: string;
  disabled: string;
  textOrange: string;
  textBlack: string;
  outlined: string;
  outlinedWhite: string;
  [key: string]: string;
}

const backgrounds: ButtonTypes = {
  primary: 'orange',
  primaryWhite: 'white',
  secondary: 'white',
  disabled: 'gray',
  textOrange: 'transparent',
  textBlack: 'transparent',
  outlined: 'transparent',
  outlinedWhite: 'transparent'
};

const colors: ButtonTypes = {
  primaryWhite: 'orange',
  primary: 'white',
  secondary: 'orange',
  disabled: 'darkGray',
  textOrange: 'orange',
  textBlack: 'black',
  outlined: 'orange',
  outlinedWhite: 'white'
};

function getBackground(type: string) {
  return backgrounds[type] || '';
}

function getColor(type: string) {
  return colors[type] || '';
}

const Button: React.FC<ButtonProps> = ({ type, icon, text, action }) => {
  return (
    type !== 'disabled' ? 
    <Container
      onClick={action}
      background={getBackground(type)}
      border={type}
      color={getColor(type)}
    >
      <Label color={getColor(type)}>{text}</Label>
    </Container>
    :
    <ContainerDisabled
      border={type}
      color={getColor(type)}
      background={getBackground(type)}
    >
      <Label color={getColor(type)}>{text}</Label>
    </ContainerDisabled>
  );
};

interface ContainerProps {
  background: string;
  border: string;
  color: string;
}
interface TextProps {
  color: string;
}

const Container = styled.button<ContainerProps>`
  display: flex;
  flex-direction: row;
  height: 56px;
  width: 100%;
  padding: 16px;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  background: ${props => Colors[props.background] };
  margin: 8px;
  border: ${props => props.border === 'outlined' || props.border === 'outlinedWhite' ? '1px solid ' + Colors[props.color] : 'none'};
  cursor: pointer;
`;

const Label = styled.div<TextProps>`
  color: ${props => Colors[props.color] };
  font-family: 'Roboto';
  font-size: 16px;
  text-align: center;
`;

const ContainerDisabled = styled.div<ContainerProps>`
  display: flex;
  flex-direction: row;
  height: 56px;
  width: 100%;
  padding: 16px;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  background: ${Colors.gray};
  margin: 8px;
  border: ${props => props.border === 'outlined' || props.border === 'outlinedWhite' ? '1px solid ' + Colors[props.color] : 'none'};
`;

export default Button;
