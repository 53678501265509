interface Colors {
  orange: string;
  lightOrange: string;
  yellow: string;
  lightYellow: string;
  green: string;
  darkGray: string;
  gray: string;
  lightGray: string;
  white: string;
  black: string;
  lightBlack: string;
  transparent: string;
  darkWhite: string;
  [key: string]: string;
}
const Colors: Colors = {
  orange: '#F75A3C',
  lightOrange:"#FEE6E2",
  yellow: '#FFEC76',
  lightYellow:'#FEEF8E',
  green: '#29B6B5',
  darkGray: '#A3A3A3',
  gray: "#DADADA",
  lightGray: "#F9FAFB",
  white: "#FDFEFF",
  darkWhite: "#F4F4F4",
  black: "#292A2C",
  lightBlack: "#666",
  transparent:"transparent"
};

export default Colors;